/* body {
      color: #5a5a5a;
    } */

.marketing {
    margin-bottom: 1.5rem;
    text-align: center;
}


.featurette-divider {
    margin: 5rem 0;
}

.featurette-heading {
    letter-spacing: -.05rem;
}

@media (min-width: 40em) {

    .featurette-heading {
        font-size: 50px;
    }
}

@media (min-width: 62em) {
    .featurette-heading {
        margin-top: 7rem;
    }
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }